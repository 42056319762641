<template>
    <div class="header">
        <h3>关于我们</h3>
        <el-row class="about">
            <el-col id="left" :span="12">
                <div class="left f-cf">
                    <div class="about-mt10">我们的服务：</div>
                    <div v-for="(v, i) in list.title" :key="i" class="about-mt10">
                        <div class="d-lb ">
                            <div class="d-lb" style="margin-right:20px;">
                                <img src="@/assets/img/关于我们/关于我们箭头.png" alt="" />
                            </div>
                            <div class="d-lb pr" style="top:2px;">
                                {{ v }}
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="12">
                <div id="right" >
                    <div class="fucontent">
                        <div class="wh100 content">
                            <div :id="'constent'+i" class="t-i" v-for="(v, i) in list.constent" :key="i">
                                {{ v }}
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        msg: String
    },
    data() {
        return {
            screenWidth: null,
            list: {
                title: ['供应链平台自研', 'SAP行业咨询实施', '跨境电商物流平台运营', '国际空运交易平台运营'],
                constent:[
                    '筹箸科技&策楷科技主要从事物流集团企业ERP项目开发、物流SaaS平台业务的定制开发、SAP管理软件咨询实施等业务。',
                    '供应链平台自研以境物流供应链平台为基础，独创性地开发B2B2B商业模式（物流需求方-物流服务商-物流承运方），将当代先进的互联网信息技术充分应用到传统的跨境物流和新兴的电子商务服务与运输领域，以整合优势资源，优化跨境物流供应链环节，降低物流成本，提高物流可视化程度，改善物流服务品质，更便捷、诚信、透明、开放的跨境物流交易与服务平台为目标的大型电子综合信息网络平台。',
                    'SAP中国的实施服务及渠道合作伙伴，SAP供应链管理软件的核心合作伙伴。',
                    '专注于企业管理信息化解决方案的研发和实施，服务涉及物流、贸易、制药、汽车、化工、机械、电子、高科技、快速消费品、金融等，公司拥有一支领先同行业的SAP实施团队，作为一家以SAP服务为核心的公司，筹箸致力于成为中国顶尖、最受社会认可、客户信赖、投资者和员工尊敬的企业信息化服务提供商。'
                ],     
            },
            height:0,
        };
    },
    watch: {
        screenWidth(val) {
            console.log(111)
            let height = document.getElementById('left').clientHeight;
            let width = document.getElementById('left').clientWidth;
            width = width += width * 0.1;
            this.height = height - 40 ;
            document.getElementById('right').style.width = width + 'px';
            document.getElementById('right').style.height = this.height + 'px';
            let count=0;
            let h =(this.height-60).toFixed(2); //计算总体高度
            for(let i in this.list.constent){
                count+=document.getElementById('constent'+i).clientHeight; //获取文本高度
            }
            let marginBottom=(h-count).toFixed(2)/3; //计算每一个的向下高度
            for(let i in this.list.constent){
                if(i!=this.list.constent.length-1){
                    document.getElementById('constent'+i).style.marginBottom=marginBottom+'px';
                }
            }
        }
    },
    mounted() { 
        this.screenWidth = document.body.clientWidth;
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth;
                this.screenWidth = window.screenWidth;
            })();
        };
    }
};
</script>

<style scoped lang="less">
.wh100 {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.about-mt10 {
    margin-bottom: 10%;
}
.about {
    .left {
        padding: 5% 10%;
        background: url('../assets/img/关于我们/关于我们.png') no-repeat;
        background-size: cover;
    }
    #right {
        background: #fff;
        margin-top: 20px;
        position: relative;
        left: -10%;
        .fucontent {
            width: 90%;
            height: calc(100% - 60px);
            padding: 30px 5%;
        }
    }
    .content{
        line-height: 24px;
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        #right {
            font-size: 12px;
        }
        .content{
            font-size: 12px;
            line-height: 17px;
        }
    }
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        #right {
            font-size: 14px;
        }
        .content{
            font-size: 14px;
            line-height: 19px;
        }
    }
    @media screen and (min-width: 1400px) and (max-width: 1600px) {
        .content{
            font-size: 14px;
            line-height: 18px;
        }
        #right {
            font-size: 16px;
        }
    }
    @media screen and (min-width: 1600px) and (max-width: 1900px) {
        .content{
            font-size:16px;
        }
        #right {
            font-size: 18px;
        }
    }
    @media screen and (min-width: 1900px) {
        #right {
            font-size:16px;
        }
    }
}
</style>
