<template>
    <div class="header ">
        <div class="f-bgf">
            <!-- xs -->
            <div class="h100 w80 f-ma">
                <el-row class="h100" :gutter="10">
                    <el-col :xl="13" :lg="10" :md="5" :sm="3" class="h100">
                        <div class="logo">
                            <img src="@/assets/img/logo.png" alt="" />
                        </div>
                    </el-col>
                    <el-col :xl="8" :lg="10" :md="13" :sm="15" class="h100">
                        <div class="h100 header-body">
                            <div
                                class="tabs"
                                v-for="(item, index) in list"
                                :key="index"
                                @click.stop.prevent="Submit(index)"
                                :class="{ active: morenIndex == index }"
                            >
                                <span>{{ item.name }}</span>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xl="3" :lg="4" :md="6" :sm="6">
                        <div class="right">
                            <img src="@/assets/img/phone.png" alt="" />
                            <span>025-86666693</span>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        msg: String
    },
    watch: {},
    data() {
        return {
            morenIndex: 0,
            list: [
                {
                    name: '首页'
                },
                {
                    name: '产品'
                },
                {
                    name: '行业解决方案'
                },
                {
                    name: '客户案例'
                },
                {
                    name: '关于我们'
                }
            ],
            divHeight: null
        };
    },
    mounted() {
        let id = [1, 2, 3, 4];
        let offsetTop = [];
        for (let i in id) {
            let value = id[i];
            let height = document.querySelector('#page' + value);
            offsetTop.push(height.offsetTop - 68);
        }
        window.addEventListener('scroll', () => {
            let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
            for (let i in offsetTop) {
                let value = offsetTop[i];
                if (top == 0) {
                    this.morenIndex = 0;
                }
                if (top >= value) {
                    this.morenIndex = id[i];
                }
            }
        });
    },
    methods: {
        Submit(key) {
            this.morenIndex = key;
            var PageId = document.querySelector('#page' + key);
            window.scrollTo({
                top: key == 0 ? 0 : PageId.offsetTop - 68,
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style scoped lang="less">
.header {
    > div {
        position: fixed;
        top: 0;
        z-index: 22;
        width: 100%;
        min-width: 992px;
        height: 68px;
        opacity: 0.9;
        .logo {
            height: 64px;
            position: relative;
            bottom: -5px;
        }
        .header-body {
            line-height: 68px;
        }
        .tabs {
            display: inline-block;
            margin-left: 26px;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            position: relative;
            text-align: center;
            cursor: pointer;
        }
        .active,.tabs:hover {
            color: #2b85f8 !important;
            font-weight: 800;
            font-size: 16px;
            border-bottom: 3px solid #2b85f8;
        }
        .right {
            background: #178cf9;
            padding: 6px 5px;
            color: #fff;
            margin-top: 21px;
            text-align: center;
            width: 90%;
            border-radius: 4px;
            img {
                position: relative;
                bottom: 2px;
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
}
</style>
