<template>
    <div class="footer">
        <div class="footer-top">
            <div v-for="(item, index) in data" :key="index" class="footer-list">
                <div>{{ item.name }}</div>
                <div v-for="(items, i) in item.children" :key="i">{{ items.name }} {{ items.value || '' }}</div>
            </div>
            <div class="footer-code">
                <div>
                    <img src="" alt="" />
                </div>
            </div>
        </div>
        <div class="record-Code">@2020-2040 筹箸科技&amp;策楷科技 备案号苏ICP备19007657号-1</div>
    </div>
</template>

<script>
export default {
    props: {
        msg: String
    },
    data() {
        return {
            data: [
                {
                    name: '产品推荐',
                    id: 1,
                    href: '', //  备选 为链接跳转准备
                    children: [
                        {
                            name: '筹箸工作流管理平台',
                            id: 2,
                            href: '',
                            children: []
                        },
                        {
                            name: '筹箸国际空运交易平台',
                            id: 3,
                            href: '',
                            children: []
                        },
                        {
                            name: '筹箸FBA跨境物流平台',
                            id: 4,
                            href: '',
                            children: []
                        },
                        {
                            name: '筹箸数字配置平台',
                            id: 5,
                            href: '',
                            children: []
                        }
                    ]
                },
                {
                    name: '服务与支持',
                    id: 6,
                    href: '',
                    children: [
                        {
                            name: '咨询电话：',
                            value: '025-86666693',
                            id: 7,
                            href: '',
                            children: []
                        },
                        {
                            name: '手机号码：',
                            value: '13917392267',
                            id: 8,
                            href: '',
                            children: []
                        },
                        {
                            name: '合作邮箱：',
                            value: 'caocm@czcek.com',
                            id: 9,
                            href: '',
                            children: []
                        },
                        {
                            name: '公司地址：',
                            value: '江苏省南京市鼓楼区清华大厦A座28楼',
                            id: 10,
                            href: '',
                            children: []
                        }
                    ]
                }
            ]
        };
    }
};
</script>

<style scoped lang="less">
.footer {
    color: #8c8d90;
    font-size: 14px;
    .footer-top {
        background: url('../assets/img/foot.png') no-repeat;
        background-size: cover;
        padding: 40px 10% 20px 10%;

        .footer-list {
            display: inline-block;
            line-height: 34px;
            width: 30%;
            white-space: nowrap;
            > div:first-child {
                color: #babcbe;
                font-size: 18px;
            }
        }
    }
    .record-Code {
        background-color: #09223a;
        line-height: 50px;
        text-align: center;
    }
    .footer-code {
        float: right;
        display: flex;
        justify-items: center;
        justify-content: center;
        > div {
            width: 150px;
            height: 150px;
            background-color: #fff;
            overflow: hidden;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
</style>
