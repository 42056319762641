<template>
    <div class="header">
        <h3>我们为什么 <img src="@/assets/img/为什么选择/问号.png" /> 需要智慧物流平台</h3>
        <el-row :gutter="30" style="padding-bottom: 20px;">
            <el-col :span="12">
                <div class="wh100" style="height:350px;">
                    <img class="wh100" src="@/assets/img/为什么选择/我们为什么左侧图.png" alt="" />
                </div>
            </el-col>
            <el-col :span="12">
                <div style="margin-top:20px;">
                    <div class="right" v-for="(v, i) in selectList.list" :key="i" style="">
                        <div class="d-lb">
                            <img src="@/assets/img/为什么选择/勾选号.png" alt="" />
                        </div>
                        <div class="d-lb sheng">
                            {{ v.title }}
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        msg: String
    },
    data() {
        return {
            selectList: {
                list: [
                    {
                        title: '快速优化内部资源，协同整合外部资源，提升服务水平'
                    },
                    {
                        title: '基于市场行情和业务情况，快速制定出强有力的价格策略，快速响应市场和客户需求'
                    },
                    {
                        title: '快速掌握业务发展和利润情况，积极做出战略决策'
                    },
                    {
                        title: '快速知晓运营风险，进行提早预防和敏捷应对'
                    },
                    {
                        title: '灵活协同子公司间高效协同，大客户项目高效协作'
                    }
                ]
            }
        };
    }
};
</script>

<style scoped lang="less">
.wh100 {
    width: 100%;
    height: 100%;
}
.right {
    margin-bottom: 30px;
    div:first-child {
        width: 7%;
    }
    div:last-child {
        width: 93%;
        position: relative;
        top: 7px;
    }
}
</style>
