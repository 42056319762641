<template>
    <div class="header">
        <h3>合作伙伴</h3>
        <div style="padding-left: 5% ">
            <el-row :gutter="10">
                <el-col class="conetent" :span="4" v-for="(v, i) in selectList.imgList" :key="i">
                    <img :src="v.url" alt="" />
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        msg: String
    },
    data() {
        return {
            selectList: {
                imgList: [
                    { url: require('@/assets/img/合作伙伴/中粮.png') },
                    { url: require('@/assets/img/合作伙伴/货代优选.png') },
                    { url: require('@/assets/img/合作伙伴/世界搬运.png') },
                    { url: require('@/assets/img/合作伙伴/千合.png') },
                    { url: require('@/assets/img/合作伙伴/进茂通.png') },
                    { url: require('@/assets/img/合作伙伴/泽坤.png') },
                    { url: require('@/assets/img/合作伙伴/二货.png') },
                    { url: require('@/assets/img/合作伙伴/敦煌.png') },
                    { url: require('@/assets/img/合作伙伴/hanwei.png') },
                    { url: require('@/assets/img/合作伙伴/SAP.png') },
                    { url: require('@/assets/img/合作伙伴/cosco.png') },
                    { url: require('@/assets/img/合作伙伴/东航.png') }
                ]
            }
        };
    }
};
</script>

<style scoped lang="less">
.conetent {
    margin-bottom: 8px;
    img {
        width: 100%;
    }
}
</style>
