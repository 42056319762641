<template>
  <div class="header pt20">
    <h3>客户案例</h3>
    <el-row :gutter="20">
      <el-col
        :span="12"
        v-for="(item, i) in selectList.list"
        :key="i"
        class="pt20"
      >
        <div class="pr case" @mouseenter="mouseenter(i)" @mouseleave="mouseleave(i)">
          <img class="w100" :src="item.url" alt="" />
          <span class="pd title">{{ item.name }}</span>
          <div :id="'caseId' + i" class="bg pd f-cf">
            <div class="title">
              <span class="heng d-lb pr"></span>
              <span class="d-lb">{{ item.name }}</span>
            </div>
            <div class="pl30 mt33">客户提升点</div>
            <div class="mt33 content" v-for="(v, j) in item.content" :key="j">
              <span class="fang"></span>
              <span>{{ v }}</span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      selectList: {
        list: [
          {
            url: require("@/assets/img/客户案例/中粮贸易.png"),
            name: "中粮贸易",
            content: [
              "全面覆盖中粮贸易运输业务流程，梳理积淀业务生产数据，完成与集团业务平台全面无缝对接，实现了贸易运输一体化的统一管理，既在集团统一平台，又能独立运行。",
              "实现公司运输经营过程的信息化、协同化、规范化；以航次为单位实现租船、调度、商务费收的统一管理，促进提升工作效率。",
              "本物流平台设计的拓展性以及物流业务的延展性，实现公司业务灵活拓展，快速对接，满足公司快速发展和业务变化的需求。",
            ],
          },
          {
            url: require("@/assets/img/客户案例/中远物流.png"),
            name: "中远物流",
            content: [
              "通过实施导入物流平台提高公司服务水平和工作效率，降低人工作业强度，提升了公司的管理，同时客户满意度大幅提升。",
              "通过系统流程规范管控和优化，避免了工作失误错误发生，特别是在客户管理、应收账款和应付账款方面增加预警机制，有效了控制了经营风险。",
              "客户拓展性以及物流业务的延展性有了很大改善和灵活性。",
              "可研管理与合同管理逻辑上保持一致，并增加了管理的严谨性。",
            ],
          },
          {
            url: require("@/assets/img/客户案例/泽坤国际货运.png"),
            name: "泽坤国际货运",
            content: [
              "多系统全面集成，信息共享，极大提高效率",
              "整体航空段的业务系统执行及信息管控",
              "提供可视化的监控过程、异常自动班别、建立主动报警和汇报机制",
              "多级运价管理机制，精细化运价管理。",
            ],
          },
          {
            url: require("@/assets/img/客户案例/进贸通.png"),
            name: "进贸通进口供应链",
            content: [
              "公司财务核算体系变革，清晰明确利润中心和成本中心，合并利润中心",
              "建立集团集中采购  控制采购成本及完成服务确认成本",
              "完善业务流程SOP，整体提高各分公司及部门节协作",
              "完善销售体系，成立营销中心，统一收益管控",
              "全面梳理客户、服务商（船公司、航空公司、拖车、仓库）定义标准服务要素，直接和订单关联，订单启动预警提示",
            ],
          },
        ],
      },
    };
  },
  methods: {
    //移入
    mouseenter(val) {
      document
        .getElementById("caseId" + val)
        .animate([{ top: "530px" }, { top: "0" }], {
          duration: 100,
        });
      document.getElementById("caseId" + val).style.top = "0";
    },
    //移出
    mouseleave(val) {
      console.log("移出");
      document
        .getElementById("caseId" + val)
        .animate([{ top: "0" }, { top: "530px" }], {
          duration: 1000,
        });
      document.getElementById("caseId" + val).style.top = "530px";
    },
  },
};
</script>

<style scoped lang="less">
.pt20 {
  padding-bottom: 20px;
}
.pl30 {
  padding-left: 30px;
}
.mt33 {
  margin-top: 33px;
}
.title {
  right: 20px;
  top: 10px;
  font-size: 28px;
}
.case {
  overflow: hidden;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .mt33 {
    margin-top: 0;
  }
  .bg{
    .title{
        font-size:20px;
    }
    .content{
        font-size: 12px;
    }
    .heng{
        top: -6px !important;
    }
    .fang{
        width: 10px !important;
        height: 10px !important;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .mt33 {
    margin-top: 13px;
  }
  .bg{
    .title{
        font-size:22px;
    }
    .content{
        font-size: 13px;
    }
    .heng{
        top: -6px !important;
    }
    .fang{
        width: 10px !important;
        height: 10px !important;
    }
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .mt33 {
    margin-top: 12px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1900px) {
  .mt33 {
    margin-top: 33px;
  }
}
@media screen and (min-width: 1900px) {
  .mt33 {
    margin-top: 40px;
  }
}
.bg {
  width: 90%;
  height: 100%;
  background: rgba(11, 42, 72, 0.7);
  top: 530px;
  padding: 0 5%;
  padding-top: 34px;
  line-height: 26px;
  .heng {
    width: 16px;
    height: 4px;
    background: #2e89f8;
    top: -8px;
    margin-right: 14px;
  }
  .fang {
    width: 16px;
    height: 16px;
    display: inline-block;
    background: #2e89f8;
    margin-right: 14px;
    transform: rotate(45deg);
    position: relative;
    left: 3px;
  }
}
</style>
