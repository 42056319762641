<template>
    <div class="home" >
        <v-Header />
        <div style="height: 68px"></div>
        <div class="page-o">
            <div id="page0" class="banner">
                <el-carousel height="100%" :interval="3000" :autoplay="true">
                    <el-carousel-item v-for="(item, i) in selectList.imgList" :key="i" >
                       <div class="pr wh100">
                            <img :src="item.url" />
                            <div class="bg pd">
                                <div class="w80 f-ma">
                                    <div v-if="i == 0" class="fs44 f-cf fw">{{ item.name }}</div>
                                    <div v-else class="fs44 f-cf fw tr">{{ item.name }}</div>
                                    <div class="contant">
                                        <div class="subject">
                                            <div class="f-cf fs24">立即咨询，免费体验</div>
                                            <div class="phone f-mt20">
                                                <div class="input pr f-bgf d-lb">
                                                    <input
                                                        onkeyup="this.value=this.value.replace(/\D/g,'')"
                                                        type="text"
                                                        v-model="from.phone"
                                                        placeholder="请输入手机号"
                                                    />
                                                    <i class="el-icon-circle-close pd icon f-c6" @click.stop="from.phone = ''" />
                                                </div>
                                            </div>
                                            <div class="msg f-mt20">
                                                <div class="input pr f-bgf d-lb">
                                                    <input
                                                        type="text"
                                                        v-model="from.msg"
                                                        placeholder="请输入验证码"
                                                    />
                                                    <i class="el-icon-circle-close pd icon f-c6" @click.stop="from.msg = ''" />
                                                </div>
                                                <span class="d-lb" @click="msgClick">{{ msg }}</span>
                                            </div>
                                            <div class="button w100 f-mt20">
                                                <button class="w100">立即体验</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div id="page1" class="f-bgF2 f-mt20">
                <div class="w80 f-ma">
                    <v-Logistics />
                </div>
            </div>
            <div id="page2" class="f-bgF2 f-mt20">
                <div class="w80 f-ma">
                    <v-Programme />
                </div>
            </div>
            <div id="page3" class="f-bgF2 f-mt20">
                <div class="w80 f-ma">
                    <v-Case />
                </div>
            </div>
            <div id="page4" class="f-bgF2 f-mt20">
                <div class="w80 f-ma">
                    <about-us />
                </div>
            </div>
        </div>
        <div class="f-bgF2 f-mt20">
            <div class="w80 f-ma">
                <why />
            </div>
        </div>
        <div class="f-mt20">
            <div class="w80 f-ma">
                <cooperation />
            </div>
        </div>
        <div class="f-mt20">
            <div class="f-ma">
                <fooTer />
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import vHeader from '@/components/header.vue';
import vLogistics from '@/components/logistics';
import vProgramme from '@/components/programme.vue';
import vCase from '@/components/case.vue';
import aboutUs from '@/components/aboutUs.vue';
import why from '@/components/why.vue';
import cooperation from '@/components/cooperation.vue';
import fooTer from '@/components/footer.vue';
export default {
    name: 'Home',
    components: {
        vHeader,
        vLogistics,
        vProgramme,
        vCase,
        aboutUs,
        why,
        cooperation,
        fooTer
    },
    data() {
        return {
            msg: '获取验证码',
            icon: false,
            icon2: false,
            from: {
                phone: null,
                msg: null
            },
            selectList: {
                imgList: [
                    {
                        url: require('@/assets/img/bannar.png'),
                        name: '用 新 一 代 信 息 技 术 构 建 智 慧 物 流 服 务 平 台'
                    },
                    {
                        url: require('@/assets/img/bannar2.png'),
                        name: '智 能 构 建 物 流 运 输 网 络'
                    },
                    {
                        url: require('@/assets/img/bannar3.png'),
                        name: '建 一 体 化 物 流 服 务 体 系'
                    }
                ]
            }
        };
    },
    methods: {
        clear(){
            console.log(11111111)
            this.from.phone = '';
        },
        msgClick() {
            if(this.msg != '获取验证码'){
                return this.$message.error('请勿重复点击')
            }
            let conut = 60;
            this.msg = conut + '秒';
            let interval = setInterval(() => {
                conut--;
                this.msg = conut + '秒';
                if (conut == 0) {
                    this.msg = '获取验证码';
                    clearInterval(interval);
                }
            }, 1000);
        }
    }
};
</script>
<style scoped lang="less">
.wh100{
    width: 100%;
    height: 100%;
}
.home {
    min-width: 992px;
}
.banner {
    height: 480px !important;
    overflow: hidden;
    .el-carousel {
        height: 100%;
    }
    .bg {
        text-align: center;
        top: 10%;
        width: 100%;
        .contant {
            background: rgba(255, 255, 255, 0.2);
            height: 248px;
            width: 400px;
            float: right;
            margin-top: 51px;
            .subject {
                width: 80%;
                margin: 0 auto;
                margin-top: 22px;
                .phone,
                .msg {
                    height: 32px;
                    line-height: 32px;
                    width: 100%;
                    .input {
                        width: 90%;
                        padding: 0 5%;
                        input {
                            width: 100%;
                        }
                        .icon {
                            top: 8px;
                            right: 5%;
                            cursor: pointer;
                        }
                    }
                }
                .msg {
                    .input {
                        width: 60%;
                    }
                    span {
                        cursor: pointer;
                        color: #666666;
                        width: 30%;
                        background: #e0e0e0;
                    }
                }
                .button {
                    background: #f3d54a;
                    padding: 5px 0;
                    text-align: center;
                }
            }
        }
    }
}
img {
    width: 100%;
    height: 100%;
}
</style>
