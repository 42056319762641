<template>
    <div class="header">
        <h3>行业解决方案</h3>
        <el-row class="programme">
            <el-col :span="8">
                <div class="left" v-for="(item, i) in selectList.list" :key="i">
                    <div class="title f-c3 fs18">{{ item.title }}</div>
                    <div class="title2 f-c6 f-mt20">{{ item.name }}</div>
                    <div class="title3 f-c6 mt3">{{ item.value }}</div>
                </div>
            </el-col>
            <el-col :span="16">
                <div class="right" style="margin-bottom: 40px;" v-for="(item, i) in selectList.imgList" :key="i">
                    <img :src="item.url" class="w100 h100" alt="" />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        msg: String
    },
    data() {
        return {
            selectList: {
                list: [
                    {
                        title: '组织、人员和流程分离的设计方案',
                        name: '表单管理',
                        value: '表单与流程匹配规则管理'
                    },
                    {
                        title: '业务规则和流程分离设计方案',
                        name: '业务协同规则管理',
                        value: '表单与流程匹配'
                    },
                    {
                        title: '权限和流程分离设计方案',
                        name: '流程及其实例管理',
                        value: '活动及其实例管理'
                    },
                    {
                        title: '端到端业务闭环流程可配置，动态挂接',
                        name: '流程协同控制',
                        value: '环节协同控制'
                    }
                ],
                imgList: [
                    {
                        url: require('@/assets/img/行业解决方案/行业解决方案1.png')
                    },
                    {
                        url: require('@/assets/img/行业解决方案/行业解决方案2.png')
                    }
                ]
            }
        };
    }
};
</script>

<style scoped lang="less">
.mt3 {
    margin-top: 3px;
}
.programme {
    padding-bottom: 20px;

    @media screen and (min-width: 992px) {
        .left:not(:last-child) {
            margin-bottom: 96px;
        }
        .right {
            height: 356px;
        }
    }
    @media screen and (min-width: 1200px) {
        .left:not(:last-child) {
            margin-bottom: 96px;
        }
        .right {
            height: 400px;
        }
    }
    @media screen and (min-width: 1900px) {
        .left:not(:last-child) {
            margin-bottom: 132px;
        }
        .right {
            height: 453px;
        }
    }
}
</style>
