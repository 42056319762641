<template>
    <div class="header">
        <h3>筹箸物流云平台</h3>
        <el-row>
            <el-col :span="12" class="pr" >
                <el-row :gutter="10">
                    <el-col
                        :id="'logisticsId' + i"
                        class="fl left"
                        :xl="8"
                        :lg="8"
                        :md="8"
                        :sm="8"
                        v-for="(item, i) in selectList.list"
                        :key="i"
                    >
                        <div class="img">
                            <img :src="item.url" alt="" />
                        </div>
                        <div class="name mt10">{{ item.title }}</div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12" >
                <div class="right">
                    <img src="@/assets/img/产品/000.png"/>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        msg: String
    },
    data() {
        return {
            screenWidth: document.body.clientWidth,
            css: {
                width: null,
                height: null
            },
            timer: false,
            selectList: {
                list: [
                    {
                        url: require('@/assets/img/产品/订单管理.png'),
                        title: '订单管理'
                    },
                    {
                        url: require('@/assets/img/产品/配置管理.png'),
                        title: '配置管理'
                    },
                    {
                        url: require('@/assets/img/产品/合同管理.png'),
                        title: '合同管理'
                    },
                    {
                        url: require('@/assets/img/产品/费用管理.png'),
                        title: '费用管理'
                    },
                    {
                        url: require('@/assets/img/产品/审批工作流.png'),
                        title: '审批工作流'
                    },
                    {
                        url: require('@/assets/img/产品/结算管理.png'),
                        title: '结算管理'
                    },
                    {
                        url: require('@/assets/img/产品/消息预警.png'),
                        title: '业务消息预警'
                    },
                    {
                        url: require('@/assets/img/产品/多级计费引擎.png'),
                        title: '多级计费引擎'
                    },
                    {
                        url: require('@/assets/img/产品/物流可视化.png'),
                        title: '物流可视化'
                    }
                ]
            }
        };
    },
    watch: {
        screenWidth(val) {
            if (val >= 1900) {
                let width = document.getElementById('logisticsId0').clientWidth;
                this.css.width = width * 3 - 93.5 + 'px';
            } else {
                let width = document.getElementById('logisticsId0').clientWidth;
                this.css.width = width * 3 + 'px';
                let height = document.getElementById('logisticsId0').clientHeight;
            }
        }
    },
    mounted() {
        const that = this;
        that.screenWidth = document.body.clientWidth;
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth;
                that.screenWidth = window.screenWidth;
            })();
        };
    },
    methods: {}
};
</script>

<style scoped lang="less">
// h3{
//   text-align: center;
//   font-size: 28px;
//   padding: 40px 0;
// }
.left {
    text-align: center;
    .name {
        margin-bottom: 60px;
    }
    img {
        width: 60px;
        height: 60%;
    }
}
.right {
    padding-bottom: 30px;
    height:388px;
}
img {
    width: 100%;
    height: 100%;
    // width: 650px;
}
</style>
